import Icon from "@/assets/icons";
import "~/fragments/FooterBase.scss";

const FooterBase = () => (
  <footer className="container-wrap footer-base">
    <div>{Icon.RavenLogoWithoutBank}</div>
    <div className="footer-base__text">
      <p>
        <span>Raven</span>: Ecosystem of Finance Tools for Africans — {new Date().getFullYear()}.{" "}
      </p>
    </div>
  </footer>
);

export default FooterBase;
