import Icon from "@/assets/icons";
import clsx from "clsx";
import { Link } from "react-router-dom";
import "~/fragments/ArrowLink.scss";

const ButtonOrLink = (props: any) => {
  if (props.onClick) {
    return <button {...props} to={undefined} />;
  }

  return <Link {...props} onClick={undefined} />;
};

type Props = {
  text: string;
  className?: string;
  to: string;
  color?: string;
  onClick?(to: string): void;
};

const ArrowLink = ({ text, className, to, color, onClick }: Props) => (
  <ButtonOrLink
    /** https://stackoverflow.com/questions/52005083/how-to-define-css-variables-in-style-attribute-in-react-and-typescript */
    style={{ color, "--arrow-link-color": color } as React.CSSProperties}
    className={clsx("arrow-link", className)}
    to={to}
    onClick={onClick ? () => onClick(to) : undefined}
  >
    {text}
    <span>{Icon.CircleArrowRightGreen}</span>
  </ButtonOrLink>
);

export default ArrowLink;
