import SuspensePreLoader from "@/components/fragments/SuspensePreLoader";
import loadable, { type LoadableComponent } from "@loadable/component";

const loadComponent = (path: string) =>
  loadable(() => import(`../pages/${path}`), { fallback: <SuspensePreLoader /> });

const HomePage = loadComponent("home");
const FaqPage = loadComponent("faq");
const Support = loadComponent("support");
const BlogPage = loadComponent("blog");
const BusinessBanking = loadComponent("businessBanking");
const AtlasPage = loadComponent("atlas");
const PosPage = loadComponent("pos");
const AboutPage = loadComponent("about-us");
const CareersPage = loadComponent("careers");
const TermsAndConditionsPage = loadComponent("terms-and-conditions");
const PrivacyAndPolicy = loadComponent("privacy-policy");
const SingleBlogArticle = loadComponent("blog/SingleBlogPost");
const FeatherInternetPage = loadComponent("feather-internet");
const HIFAPage = loadComponent("hifa");
const BlockAccountPage = loadComponent("block-account/page");
const BlockAccountActionPage = loadComponent("block-account/[slug]/page");

interface Route {
  path: string;
  element: LoadableComponent<unknown>;
}

export const all_route_group: Route[] = [
  { path: "/", element: HomePage },
  { path: "/faq", element: FaqPage },
  { path: "/support", element: Support },
  { path: "/raven-business", element: BusinessBanking },
  { path: "/atlas", element: AtlasPage },
  { path: "/bankbox", element: PosPage },
  { path: "/about-us", element: AboutPage },
  { path: "/careers", element: CareersPage },
  { path: "/terms-and-conditions", element: TermsAndConditionsPage },
  { path: "/blog", element: BlogPage },
  { path: "/blog/:title", element: SingleBlogArticle },
  { path: "/privacy-policy", element: PrivacyAndPolicy },
  { path: "/feather-internet", element: FeatherInternetPage },
  { path: "/hifa", element: HIFAPage },
  { path: "/block-account", element: BlockAccountPage },
  { path: "/block-account/:slug", element: BlockAccountActionPage },
];
