import "~/fragments/DownloadAppLinks.scss";
import PlayStoreBlack from "@/assets/icons/DownloadAppLinks/DownloadPlayStoreBlack.png";
import PlayStoreWhite from "@/assets/icons/DownloadAppLinks/DownloadPlayStoreWhite.png";
import AppleBlack from "@/assets/icons/DownloadAppLinks/DownloadAppleBlack.png";
import AppleWhite from "@/assets/icons/DownloadAppLinks/DownloadAppleWhite.png";

interface Props {
  type?: "light" | "dark";
}

const DownloadAppButtons = ({ type = "dark" }: Props) => (
  <div className="download__app">
    <a
      href="https://apps.apple.com/ng/app/raven-the-peoples-bank/id1572381363"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={type === "light" ? AppleWhite : AppleBlack} alt="Download on the app store" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=com.raven.raven&hl=en&gl=NG"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={type === "light" ? PlayStoreWhite : PlayStoreBlack} alt="Download on  playstore" />
    </a>
  </div>
);

export default DownloadAppButtons;
