import React, { createContext, useContext, useRef } from "react";

const QueryContext = createContext<AppCache<any>>({});

export const QueryProvider: React.FC<IChildren> = ({ children }) => {
  const cache = useRef<AppCache<any>>({});

  return <QueryContext.Provider value={cache.current}>{children}</QueryContext.Provider>;
};

export const useQueryCache = () => {
  return useContext(QueryContext);
};
