import React, { Fragment, useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SuspensePreLoader from "@/components/fragments/SuspensePreLoader";
import "~/base/Layouts.scss";
import clsx from "clsx";
import AOS from "aos";
import "aos/dist/aos.css";
import { ParallaxProvider } from "react-scroll-parallax";
import { useLocation } from "react-router-dom";
import { SpecialUrlLinks as HardLinks } from "@/constants";
import getUserAgent from "@/utils/getUserAgent";
import { handleRedirect } from "@/utils";
import { useLayoutDispatch } from "@/context/LayoutContex";

interface Props extends LayoutStatusProps {
  children?: React.ReactNode;
  FooterHeader?: React.ForwardRefExoticComponent<React.RefAttributes<HTMLDivElement>>;
  hideFooter?: true;
  className?: string;
  disableLayout?: boolean;
}

type THardLinks = typeof HardLinks;

type HardLinksKeys = keyof THardLinks;

export type AppOrPlayStoreURL = THardLinks["pos"] | THardLinks["personalBanking"];

export const handleAppStoreRedirect = (url: AppOrPlayStoreURL) => {
  switch (getUserAgent()) {
    case "Android":
    case "Windows":
    case "unknown":
      handleRedirect(url.playstore);
      return;
    case "iOS":
      handleRedirect(url.appstore);
      return;
    default:
      handleRedirect(url.playstore);
      return;
  }
};

const MainLayout = (props: Props) => {
  const { children, FooterHeader, hideFooter, className, disableLayout, ...rest } = props;
  const { type = "light-header", loading, imageIsLoading = false } = rest;

  const [showPreloader, setShowPreloader] = useState(true);

  const footerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const slug = location.pathname.replace("/", "").toLowerCase() as HardLinksKeys;

  const handleClick = () => {
    const { pos, personalBanking, ...SignUpLinks } = HardLinks;

    if (slug in SignUpLinks) {
      handleRedirect(String(HardLinks[slug]));
    } else if (slug === "pos") {
      handleAppStoreRedirect(pos);
    } else {
      handleAppStoreRedirect(personalBanking);
    }
  };
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setShowPreloader(false);
    }, 800);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 800,
    });
  }, []);

  const preloaderStatus = showPreloader || imageIsLoading;

  useLayoutDispatch(
    (state) => {
      state = rest;
      state.imageIsLoading = preloaderStatus;
    },
    [rest, preloaderStatus]
  );

  return (
    <Fragment>
      {!loading ? (
        <Fragment>
          <div className={clsx("show--preloader", !preloaderStatus && "hide-preloader-content")}>
            {preloaderStatus && <SuspensePreLoader />}
          </div>
          <div className={clsx("content--loading", !showPreloader && "content--loaded")}>
            {!disableLayout && <Header pathName={slug} onGetStarted={handleClick} type={type} />}
            <ParallaxProvider>
              <main className={className}>{children}</main>
            </ParallaxProvider>
            {!hideFooter && !disableLayout && <Footer ref={footerRef} Header={FooterHeader} />}
          </div>
        </Fragment>
      ) : (
        <div className={clsx("show--preloader")}>
          <SuspensePreLoader />
        </div>
      )}
    </Fragment>
  );
};

export default MainLayout;
