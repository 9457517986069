import React from "react";
import ReactDOM from "react-dom/client";
import App from "@/App";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.scss";
import { QueryProvider } from "./context/QueryProvider";
import { LayoutProvider } from "./context/LayoutContex";
// import { MDXProvider } from "@mdx-js/react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <QueryProvider>
        <LayoutProvider>
          <>
            <App />
          </>
        </LayoutProvider>
      </QueryProvider>
    </React.StrictMode>
  </BrowserRouter>
);
