import { FooterNavItems } from "@/constants";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import QRCode from "react-qr-code";
import "~/fragments/FooterMidSection.scss";

const QRArea = () => (
  <QRCode
    size={100}
    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
    value={`https://onelink.to/pakw9r`}
    viewBox={`0 0 256 256`}
    className="img-box"
  />
);

type FooterRowProps = {
  title: string;
  options: { text: string; link: string; renderAnchor?: boolean }[];
  onClick(link: string): void;
};

const FooterRow = ({ title, options, onClick }: FooterRowProps) => {
  return (
    <div className="footer-mid__row">
      <div>
        <h4>{title}</h4>
        <ul className="footer-mid__row--list">
          {options.map(({ link, text, renderAnchor }) => (
            <li key={link}>
              {!renderAnchor ? (
                <Link to={link} onClick={() => onClick(link)}>
                  {text}
                </Link>
              ) : (
                <a href={link}>{text}</a>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const FooterMidSection = () => {
  const location = useLocation();

  const handleClick = (link: string) => {
    if (link === location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="footer-mid container-wrap">
      <div className="footer-mid__wrapper">
        {FooterNavItems.map(({ title, links, className }) => (
          <div key={title} className={clsx(className && `footer-mid--${className}`)}>
            <FooterRow title={title} options={links} onClick={handleClick} />
          </div>
        ))}
      </div>

      <div className="footer-mid__legal">
        <div className="footer--bardcode">
          <QRArea />
        </div>
        <p>
          All Deposits Are Insured By The Nigerian Deposit Insurance Corporation (NDIC). Raven Is
          powered by Beststar MFB a Licensed MF Bank by the Central Bank Of Nigeria. “Raven” is a
          trademark of Ravenpay LTD. Plot 20 Layi Yusuf Crescent, off Admiralty way, Lekki Lagos.
        </p>
      </div>
    </div>
  );
};

export default FooterMidSection;
