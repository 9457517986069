import NotFoundUI from "@/components/blocks/not-foundUI";
import MainLayout from "@/layout";

const NotFoundPage = () => (
  <MainLayout hideFooter>
    <NotFoundUI />
  </MainLayout>
);

export default NotFoundPage;
