import type { SpecialUrls } from "@/constants";
import React from "react";

export const handleRedirect = (url: SpecialUrls | (unknown & {}), target = "_blank") => {
  const sURL = String(url);

  window.open(sURL, "_blank") || window.location.assign(sURL);
};

export const handleScrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const convertToLegalBody = (
  tab: ILegalContent[] | React.ReactNode
): ILegalContentBody[] | React.ReactNode => {
  if (!Array.isArray(tab)) return tab;

  return tab.map((opt) => {
    return {
      ...opt,
      to: opt?.to ?? opt.title.replace(/[\s,]/g, "-").toLowerCase(),
    };
  });
};

export const isOddLastChild = (arrayLength: number, idx: number) => {
  return arrayLength % 2 !== 0 && idx === arrayLength - 1;
};

export const getBlogReadTime = (word: string) => {
  const str = word.split("…");

  return str[1] ?? "4 min read  ";
};

export const iife = <T>(fn: () => T) => fn();

export function isObjectWithValidValues(obj: any): boolean {
  // Check if the object is defined and not null
  if (obj === undefined || obj === null || typeof obj !== "object") {
    return false;
  }

  // Check if the object has at least one property
  if (Object.keys(obj).length === 0) {
    return false;
  }

  // Check each key-value pair for empty string or null
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      // Check if the value is null or an empty string
      if (value === null || value === "") {
        return false;
      }
    }
  }

  return true;
}

export function getQueryVariable(variable: string) {
  //"app=article&act=news_content&aid=160990"
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (let i = 0; i < vars.length; i++) {
    //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    const pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }

  return false;
}
