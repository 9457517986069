import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as Dialog from "@radix-ui/react-dialog";
import Icon from "@/assets/icons";
import { Link } from "react-router-dom";
import { HeaderNavItems } from "@/constants";
import "~/layout/MobileMenu.scss";
import useWindowSize from "@/hooks/useWindowSize";
import { ProductAdsSlider } from "@/components/product-ad";

const variants = {
  open: {
    transition: { staggerChildren: 0.1, delayChildren: 0.1 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [dropDown, setDropDown] = useState<string>();

  const screenSize = useWindowSize();

  useEffect(() => {
    if (screenSize > 991) {
      setIsOpen(false);
    }
  }, [screenSize]);

  const handleDropDownClick = (name: string) => {
    if (dropDown !== name) {
      setDropDown(name);
    } else {
      setDropDown(undefined);
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>
        <button className="header__mobile_menu" type="button">
          <span className="raven-sr-only">Toggle Menu</span>
          {Icon.HamburgerMenu}
        </button>
      </Dialog.Trigger>

      <AnimatePresence>
        {isOpen && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay forceMount />
            <Dialog.Content forceMount asChild>
              <motion.div
                initial={{ x: "-100vw", scaleX: 0 }}
                exit={{ x: "-100vw", scaleX: 0 }}
                animate={{ x: 0, scaleX: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 350,
                  damping: 35,
                  duration: 0.9,
                }}
                className="header__mobile_menu__content hide-scrollbar"
              >
                <div className="hide-scrollbar menu-view-area">
                  <div className="header__mobile_menu__header">
                    <Link className="header--logo" to="/">
                      {Icon.RavenLogoWithoutBank}
                    </Link>
                    <Dialog.Close asChild>
                      <button className="close-modal-button" type="button">
                        <span className="raven-sr-only">Close Menu</span>
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6.5L6 18.5M6 6.5L18 18.5"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </Dialog.Close>
                  </div>

                  <ul className="header__mobile_menu__dropdown">
                    {HeaderNavItems.map(({ name, submenu, link }, idx) => (
                      <div className="" key={name}>
                        {!link ? (
                          <button
                            onClick={() => handleDropDownClick(name)}
                            className="header__mobile_menu__dropdown--trigger"
                            type="button"
                          >
                            <span>{name}</span>
                            {submenu && Icon.ChevronDown}
                          </button>
                        ) : (
                          <Link
                            className="header__mobile_menu__dropdown--trigger"
                            to={link}
                            onClick={() => setIsOpen(false)}
                          >
                            {name}
                          </Link>
                        )}

                        {dropDown === name && (
                          <motion.div initial="closed" animate="open" variants={variants}>
                            {submenu &&
                              submenu.subItems.map(({ title, link, icon }) => (
                                <motion.div
                                  key={title}
                                  variants={{
                                    open: {
                                      y: 0,
                                      opacity: 1,
                                      transition: {
                                        y: { stiffness: 1000, velocity: -600 },
                                      },
                                    },
                                    closed: {
                                      y: -30,
                                      opacity: 0,
                                      transition: { type: "spring", duration: 0.1 },
                                    },
                                  }}
                                  className="header__mobile_menu__dropdown__content"
                                >
                                  <Link
                                    onClick={() => setIsOpen(false)}
                                    className="dropdown__link"
                                    to={link}
                                  >
                                    <span className="dropdown__link__icon">{icon}</span>
                                    {title}
                                  </Link>
                                </motion.div>
                              ))}
                          </motion.div>
                        )}
                      </div>
                    ))}
                  </ul>
                  <div className="header-announcement">
                    <ProductAdsSlider />
                  </div>
                </div>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};

export default MobileMenu;
