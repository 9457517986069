/** ---really important---
 *
 * Use HideContent wrapper to keep components in the dom... a good usecase is with images
 * Images placed in hide content would remain in the dom always, so prevent further network calls
 */
const HideContent = ({ children }: { children: React.ReactNode }) => (
  <div style={{ visibility: "hidden", width: 0, height: 0, overflow: "hidden" }}>{children}</div>
);

export default HideContent;
