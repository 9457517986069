import { createContext, useContext, useEffect, useRef } from "react";

const initialState: LayoutStatusProps = {
  imageIsLoading: false,
  loading: false,
  type: "light-header",
};

const LayoutContext = createContext<LayoutStatusProps>(initialState);

export const LayoutProvider: React.FC<IChildren> = ({ children }) => {
  const cache = useRef(initialState);

  return <LayoutContext.Provider value={cache.current}>{children}</LayoutContext.Provider>;
};

export const useLayoutContext = () => {
  return useContext(LayoutContext);
};

/** do not use spread operators when updating state
 * update the state directly as the spread operator would create a new instance of the state
 * and the updates wouldn't reflect
 * @example ```tsx
 *useLayoutDispatch(
 * (state) => {
 *  state.loading = isLoading;
 *  state.imageIsLoading = imageIsLoading;
 * },
 * [isLoading, imageIsLoading]
 *);
 * ```
 */
export const useLayoutDispatch = (
  effect: (state: LayoutStatusProps) => void,
  deps: unknown[] = []
) => {
  const state = useLayoutContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    effect(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, ...deps]);

  return state;
};
