import { RavenButton } from "@ravenpay/raven-bank-ui";
import { Link, useNavigate } from "react-router-dom";
import "~/not-found/NotFound.scss";
import NotFoundImg from "@/assets/NotFound/404.png";
import BirdIllustration from "@/assets/NotFound/BirdIllustration.png";
import { motion } from "framer-motion";

const NotFoundUI = () => {
  const navigate = useNavigate();

  return (
    <section className="nfp">
      <div className="container-wrap nfp-content">
        <div className="nfp__banner" style={{ backgroundImage: `url(${NotFoundImg})` }} />
        <motion.div
          className="nfp__illustration"
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", bounce: 0.6, delay: 0.8 }}
        >
          <motion.img src={BirdIllustration} alt="" />
        </motion.div>
        <div className="nfp__content">
          <h1 className="raven-text-grey-800 nfp-header">Page not Found</h1>
          <p>
            We have provided APIs which allow you to create unlimited virtual or physical cards
            within few minutes.
          </p>
          <RavenButton onClick={() => navigate(-1)} color="green-light">
            Get Back to Safety
          </RavenButton>
        </div>
      </div>
    </section>
  );
};

export default NotFoundUI;
