import Icon from "@/assets/icons/index";
import { useEffect, useRef, useState } from "react";
import { HeaderNavItems } from "@/constants";
import { useOnClickOutside } from "@/hooks/useOnClickOutside";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import NavbarFade from "@/components/fragments/Header/NavbarFade";
import { Link } from "react-router-dom";
import HeaderDropDown from "@/components/fragments/Header/DropDown";
import "~/layout/Header.scss";
import MobileMenu from "./MobileMenu";
import clsx from "clsx";
import { handleScrollToTop } from "@/utils";
import HideContent from "@/components/fragments/HideContent";
import HeaderIcon from "@/assets/Header/FeatherInternet.png";
import HeaderIcon2 from "@/assets/Header/NewProducts.png";

interface Props {
  type: "light-header" | "dark-header";
  onGetStarted(): void;
  pathName: string;
}

const Header = ({ type, onGetStarted, pathName }: Props) => {
  const [selected, setSelected] = useState<string>();

  const headerRef = useRef<HTMLDivElement>(null);

  const dropdownRef = useOnClickOutside(() => setSelected(undefined));

  useEffect(() => {
    if (!headerRef.current) return;

    const header = headerRef.current;

    const handleScrollEffect = () => {
      if (window.scrollY > 30) {
        header.classList.add("header--scroll");
      } else {
        header.classList.remove("header--scroll");
      }
    };

    document.addEventListener("scroll", handleScrollEffect);

    return () => {
      document.removeEventListener("scroll", handleScrollEffect);
    };
  }, []);

  return (
    <NavbarFade reverseEffect={() => setSelected(undefined)}>
      <HideContent>
        <img src={HeaderIcon} alt="" />
        <img src={HeaderIcon2} alt="" />
      </HideContent>
      <header ref={headerRef} className={clsx("header", type === "dark-header" && "header--dark")}>
        <div className="container-wrap header--main">
          <Link onClick={handleScrollToTop} className="header--logo" to="/">
            {type === "light-header" ? Icon.RavenLogoWithoutBank : Icon.RavenBankLogoWhite}
          </Link>

          <div className="header__navbar" ref={dropdownRef}>
            {HeaderNavItems.map(({ name, submenu, link }, idx) => (
              <div className="header__navbar__item" key={name}>
                {!link ? (
                  <button
                    className="header__navbar__item--trigger"
                    type="button"
                    onClick={() => {
                      if (selected !== name) {
                        setSelected(name);
                      } else {
                        setSelected(undefined);
                      }
                    }}
                  >
                    <span>{name}</span>
                    {submenu && Icon.ChevronDown}
                  </button>
                ) : (
                  <Link
                    className={clsx("header__navbar__item--trigger", !submenu && "offset-2")}
                    to={link}
                  >
                    {name}
                  </Link>
                )}

                {submenu && selected === name && (
                  <HeaderDropDown open={selected === name} submenu={submenu} idx={idx} />
                )}
              </div>
            ))}
          </div>
          <div className="header__right">
            <RavenButton
              size="small"
              color={type === "dark-header" ? "white-light" : "green-light"}
              label="Get Started"
              onClick={onGetStarted}
            />
          </div>
          <MobileMenu />
        </div>
      </header>
    </NavbarFade>
  );
};

export default Header;
