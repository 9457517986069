import Icon from "@/assets/icons/index";
import MoneyMatters from "@/assets/BlogDummy/MoneyMatters.jpeg";
import DebtFreeLiving from "@/assets/BlogDummy/DebtFreeLiving.jpeg";
import FeatherInternet from "@/assets/BlogDummy/FeatherInternet.jpeg";
import { ProductAdsSlider } from "@/components/product-ad";

export const SpecialUrlLinks = {
  pos: {
    playstore: "https://play.google.com/store/apps/details?id=com.raven.raven_pos_manager&hl=en_US",
    appstore: "https://apps.apple.com/ng/app/raven-pos/id6443548562",
  },
  "bank-box": {
    playstore: "https://play.google.com/store/apps/details?id=com.raven.raven_pos_manager&hl=en_US",
    appstore: "https://apps.apple.com/ng/app/raven-pos/id6443548562",
  },
  atlas: "https://atlas.getravenbank.com/register",
  "business-banking": "https://business.getravenbank.com/sign-up",
  "raven-business": "https://business.getravenbank.com/sign-up",
  personalBanking: {
    playstore: "https://play.google.com/store/apps/details?id=com.raven.raven&hl=en&gl=NG",
    appstore: "https://apps.apple.com/ng/app/raven-the-peoples-bank/id1572381363",
  },
} as const;

export type TSpecialUrlLinks = CreateMutableType<typeof SpecialUrlLinks>;

export type SpecialUrls = UnwrapValues<TSpecialUrlLinks>;

type AdditionalContent = {
  title: string;
  desc: string;
  cta: string;
  svg: string;
  link?: string;
  className?: string;
};

export type SubItems = {
  icon: JSX.Element;
  title: string;
  desc: string;
  link: string;
};

type NavData = {
  name: string;
  link?: string;
  submenu?: {
    subItems: SubItems[];
    additionalContent?: AdditionalContent;
    content?: () => JSX.Element;
  };
}[];

export const HeaderNavItems: NavData = [
  {
    name: "Products",
    submenu: {
      subItems: [
        {
          icon: Icon.PersonalBanking,
          desc: "Enjoy countless features on our personal banking",
          link: "/",
          title: "Raven Personal",
        },
        {
          icon: Icon.RavenBusiness,
          desc: "Digital product for retailers and small business owners",
          link: "/raven-business",
          title: "Raven business",
        },
        {
          icon: Icon.RavenAtlas,
          desc: "Incorporate financial services in your business",
          link: "/atlas",
          title: "Raven Atlas",
        },
        {
          icon: Icon.RavenPOS,
          desc: "Receive payments seamlessly from customers",
          link: "/bankbox",
          title: "BankBox",
        },
      ],
      content: ProductAdsSlider,
    },
  },
  {
    name: "Company",
    submenu: {
      subItems: [
        {
          icon: Icon.RavenAboutUs,
          desc: "See all you need to know about our company",
          link: "/about-us",
          title: "About Us",
        },
        {
          icon: Icon.RavenCareers,
          desc: "Think you got what it takes to join us on raven ?",
          link: "/careers",
          title: "Careers",
        },
        {
          icon: Icon.RavenSupport,
          desc: "Looking forward to getting in touch with you",
          link: "/support",
          title: "Support",
        },
        {
          icon: Icon.RavenBlog,
          desc: "See what we're up to at the Raven HQ",
          link: "/blog",
          title: "Raven Blog",
        },
        {
          icon: Icon.RavenLegal,
          desc: "Terms and conditions , privacy policy here",
          link: "/terms-and-conditions",
          title: "Legal",
        },
      ],
      content: ProductAdsSlider,
    },
  },
  {
    name: "FAQ's",
    link: "/faq",
  },
];

export const FooterNavItems = [
  {
    title: "Products",
    links: [
      { text: "Raven Personal", link: "/" },
      { text: "Raven Business", link: "/raven-business" },
      { text: "Raven Atlas", link: "/atlas" },
      { text: "BankBox", link: "/bankbox" },
      { text: "HIFA Savings", link: "/hifa" },
      // { text: "Webpay", link: "/webpay" },
    ],
  },
  {
    title: "Company",
    links: [
      { text: "About Us", link: "/about-us" },
      { text: "Careers", link: "/careers" },
      { text: "Blog", link: "/blog" },
      { text: "Feather Internet", link: "/feather-internet" },
    ],
  },
  {
    title: "Help",
    className: "",
    links: [
      { text: "FAQ", link: "/faq" },
      { text: "Support", link: "/support" },
      { text: "Block Account", link: "/block-account" },
      {
        text: "support@getravenbank.com",
        link: "mailto:support@getravenbank.com",
        renderAnchor: true,
      },
    ],
  },
  {
    title: "Legal",
    // className: "show-lg",
    links: [
      { text: "Privacy Policy", link: "/privacy-policy" },
      { text: "Terms and Conditions", link: "/terms-and-conditions" },
    ],
  },
];

export const TempBlogPostTab = ["All blogs"] as const;

export const BLOGPOST_TABS = [
  "All blogs",
  "Finance",
  "Engineering",
  "Design resources",
  "Feather internet",
] as const;

type POST = {
  category: (typeof BLOGPOST_TABS)[number][];
  title: string;
  date: string;
  src: string;
  readTime: string;
  isRecent: boolean;
  isTop: boolean;
  link: string;
  isRelated?: true;
};

/** do not update links for blog post without updating blog post route */
export const BLOG_POST: Array<POST> = [
  {
    category: ["Feather internet"],
    date: "July 10",
    readTime: "3 min",
    title: "Raven Feather Internet (The Story)",
    isRecent: false,
    isTop: true,
    src: FeatherInternet,
    link: "/blog/the-feather-internet-story",
    isRelated: true,
  },
  {
    category: ["Finance"],
    title: "How I Made Money In School, Living Comfortably As A Student.",
    date: "Jun 14",
    src: "https://miro.medium.com/max/1400/1*Vpl4OCUZpKOkaSatYRO7QA.webp",
    readTime: "8 min",
    isRecent: false,
    isTop: true,
    link: "/blog/how-i-made-money-in-school-living-comfortably-as-a-student",
    isRelated: true,
  },
  {
    category: ["Finance"],
    date: "Jun 12",
    readTime: "4 min",
    title: "Money Matters with Raven: Freeing Yourself from Financial Stress as an Adult",
    isRecent: false,
    isTop: true,
    src: MoneyMatters,
    link: "#",
  },
  {
    category: ["Finance"],
    date: "Jun 8",
    readTime: "4 min",
    title: "5 Tips For Living A Debt-Free Life",
    isRecent: true,
    isTop: false,
    src: DebtFreeLiving,
    link: "#",
  },
  {
    category: ["Finance"],
    date: "Jun 11",
    readTime: "4 min",
    title: "We Took A Work Trip Abroad!",
    isRecent: true,
    isTop: false,
    src: "https://miro.medium.com/max/1400/1*gEfj_OPe59-oxLy0uinLpQ.webp",
    link: "/blog/we-took-a-work-trip-abroad",
    isRelated: true,
  },
  {
    category: ["Finance"],
    date: "Jun 11",
    readTime: "4 min",
    title: "The World's Best Financial Services",
    isRecent: true,
    isTop: false,
    src: "https://miro.medium.com/max/1400/1*QLt5GZMoMp0SdkPnIdTQGQ.webp",
    link: "/blog/the-worlds-best-financial-services",
    isRelated: true,
  },
];

export interface University {
  name: string;
  isActive?: boolean;
}

export interface LocationCoordinates {
  top?: string;
  right?: string;
  left?: string;
  bottom?: string;
}

export interface IFeatherLocations {
  location: string;
  isActive?: boolean;
  universities: University[];
  coordinates: LocationCoordinates;
}

export const FeatherLocations: IFeatherLocations[] = [
  {
    location: "Lagos",
    universities: [
      { name: "Yaba Institute of Technology" },
      { name: "University of Lagos" },
      { name: "Lagos State University" },
    ],
    coordinates: { bottom: "24%", left: "3%" },
  },
  {
    location: "Osun",
    universities: [{ name: "Obafemi Awolowo University" }],
    coordinates: { bottom: "30%", left: "13%" },
  },
  {
    location: "Oyo",
    universities: [{ name: "University of Ibadan" }, { name: "LAUTECH" }],
    coordinates: { bottom: "36%", left: "3%" },
  },
  {
    location: "Illorin",
    universities: [{ name: "University of Illorin" }],
    coordinates: { bottom: "44%", left: "7%" },
  },
  {
    location: "Delta",
    universities: [{ name: "University of Delta" }],
    coordinates: { bottom: "8%", left: "19%" },
  },
  {
    location: "Abuja",
    universities: [{ name: "University of Abuja" }],
    coordinates: { bottom: "40%", left: "40%" },
  },
  {
    location: "Kaduna",
    universities: [{ name: "Ahmadu Bello University" }],
    coordinates: { bottom: "55%", left: "45%" },
  },
  {
    location: "Jos",
    universities: [{ name: "University of Jos" }],
    coordinates: { bottom: "45%", left: "55%" },
  },
  {
    location: "Benue",
    universities: [{ name: "Benue State University" }],
    coordinates: { bottom: "28%", left: "50%" },
  },
  {
    location: "Edo",
    universities: [{ name: "University of Benin" }],
    coordinates: { bottom: "15%", left: "25%" },
  },
  {
    location: "Anambra",
    universities: [{ name: "Nnamdi Azikiwe university" }],
    coordinates: { bottom: "25%", left: "30%" },
  },
  {
    location: "Owerri",
    universities: [{ name: "Imo State University" }, { name: "FUTO" }],
    coordinates: { bottom: "12%", left: "34%" },
  },
  {
    location: "Enugu",
    universities: [{ name: "University of Nigeria" }],
    coordinates: { bottom: "19%", left: "41%" },
  },
  {
    location: "Port Harcourt",
    universities: [
      { name: "Ignatus Ajiru University", isActive: true },
      { name: "Rivers State University" },
    ],
    coordinates: { bottom: "2%", left: "33%" },
    isActive: true,
  },
  {
    location: "Uyo",
    universities: [{ name: "University of Uyo" }],
    coordinates: { bottom: "8%", left: "47%" },
  },
  {
    location: "Cross River",
    universities: [{ name: "University of Calabar" }],
    coordinates: { bottom: "16%", left: "53%" },
  },
];

export const FeatherInternetUniversities: University[] = [
  { name: "Rivers state University" },
  { name: "University of port harcourt" },
  { name: "University of Illorin" },
  { name: "University of Abuja" },
  { name: "University of Benin" },
  { name: "Nnamdi azikwe University" },
  { name: "University of uyo" },
  { name: "Lagos state University" },
  { name: "Yaba college of technology" },
  { name: "University of Nigeria nssuka" },
  { name: "Obafemi awolowo University" },
  { name: "University of calabar" },
  { name: "University of ibadan" },
  { name: "FUTO-owerri" },
  { name: "Imo state University" },
  { name: "University of jos" },
  { name: "Delta state University" },
  { name: "Benue state University" },
  { name: "Ahmadu Bello University" },
  { name: "Ladoke Akintola University" },
  { name: "Ignatus ijayi University", isActive: true },
];

export interface TProductField {
  label: string;
  value: keyof typeof SpecialUrlLinks;
}

export const ProductFields: TProductField[] = [
  { label: "Personal", value: "personalBanking" },
  { label: "Business", value: "business-banking" },
  { label: "Atlas", value: "atlas" },
  { label: "BankBox", value: "pos" },
];

export const ProductFieldsMobile: TProductField[] = [
  { label: "Personal", value: "personalBanking" },
  { label: "Business", value: "business-banking" },
  { label: "Atlas", value: "atlas" },
  { label: "BankBox", value: "pos" },
];
