import AppImage from "@/assets/Footer/FooterAppImage.png";
import "~/fragments/FooterHeader.scss";
import DownloadAppButtons from "../DownloadAppButtons";
import { forwardRef } from "react";

const FooterHeader = forwardRef<HTMLDivElement>((_, ref) => (
  <div ref={ref} className="footer-header">
    <div className="footer-header__top">
      <div className="container-wrap footer-header__top__content">
        <div className="footer-header__top__content--text">
          <div className="footer-header--content">
            <h2 className="footer-header-title">
              Send, Receive and save money with zero troubles, anyday, anytime!
            </h2>
            <p>All you need on your personal finance journey</p>
            <div className="footer-header--app--wrap">
              <DownloadAppButtons type="light" />
            </div>
          </div>
        </div>
        <div className="image--wrapper">
          <img src={AppImage} alt="App demo" />
        </div>
      </div>
      <div className="footer-header__top__spirals" />
    </div>
  </div>
));

export default FooterHeader;
