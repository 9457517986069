import FooterHeader from "@/components/fragments/Footer/FooterHeader";
import FooterMidSection from "@/components/fragments/Footer/FooterMidSection";
import FooterBase from "@/components/fragments/Footer/FooterBase";
import { forwardRef } from "react";

interface Props {
  Header?: React.ForwardRefExoticComponent<React.RefAttributes<HTMLDivElement>>;
}

const Footer = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { Header } = props;

  return (
    <div className="footer">
      {Header ? <Header ref={ref} /> : <FooterHeader ref={ref} />}
      <FooterMidSection />
      <FooterBase />
    </div>
  );
});

export default Footer;
