import { Swiper } from "swiper/react";
import type { SwiperProps } from "swiper/react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "~/reusables/SwiperJS.scss";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css/effect-fade";

const SwiperJS = (props: SwiperProps) => {
  return (
    <Swiper
      effect="fade"
      autoplay={{
        delay: 2500,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      loop
      modules={[Autoplay, EffectFade]}
      {...props}
    />
  );
};

SwiperJS.Slide = SwiperSlide;

export default SwiperJS;
