/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 */
const getUserAgent = () => {
  // @ts-expect-error
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows" as const;
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-expect-error
  if (/iPad|iPhone|iPod|Mac/.test(userAgent) && !window.MSStream) {
    return "iOS" as const;
  }

  return "unknown" as const;
};

export default getUserAgent;
