import ArrowLink from "../fragments/ArrowLink";
import FeatherIcon from "@/assets/Header/FeatherInternet.png";
import HifaAdIllustration from "@/assets/ProductAds/HIFA.png";
import BusinessBankingADIllustration from "@/assets/ProductAds/BusinesBanking.png";
import "~/product-ads/index.scss";
import SwiperJS from "../reuseable/SwiperJS";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const handleDummyClick = () => {};

const useNavigateTo = (url: string) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(url);
  };

  return handleClick;
};

export const FeatherInternetAd = () => {
  const navigate = useNavigateTo("/feather-internet");

  return (
    <article onClick={navigate} className="product-ad feather-ad">
      <div className="product-ad__header">
        <h4 className="ad-title">Raven feather internet project</h4>
        <p className="ad-text">Enjoy Free Internet when you use Raven in your school environment</p>
        <ArrowLink
          to="/feather-internet"
          onClick={handleDummyClick}
          text="Learn how to get connected"
        />
      </div>
      <motion.img
        className="product-ad__illustration"
        src={FeatherIcon}
        alt=""
        initial={{
          y: 30,
          opacity: 0.7,
          rotateY: 0,
        }}
        whileInView={{ y: 0, opacity: 1, rotateY: 0 }}
        transition={{ duration: 0.6 }}
      />
    </article>
  );
};

export const BusinessBankingAd = () => {
  const navigate = useNavigateTo("/raven-business");

  return (
    <article onClick={navigate} className="product-ad">
      <div className="product-ad__header">
        <h4 className="ad-title">Discover the all new business banking</h4>
        <p className="ad-text">
          Are you a business owner looking to scale your business ? stay ahead with our business
          banking tools
        </p>
        <ArrowLink to="#" text="Learn more" color="#755AE2" onClick={handleDummyClick} />
      </div>
      <img className="product-ad__illustration" src={BusinessBankingADIllustration} alt="" />
    </article>
  );
};

export const HIFAAd = () => {
  const navigate = useNavigateTo("/hifa");

  return (
    <article onClick={navigate} className="product-ad">
      <div className="product-ad__header">
        <h4 className="ad-title">Discover a new way to save with HIFA</h4>
        <p className="ad-text">
          (HIFA) High-interest fixed accounts are attractive to individuals who want to earn a
          higher return on their savings while minimizing risk
        </p>
        <ArrowLink text="Learn more" to="/hifa" onClick={handleDummyClick} color="#755AE2" />
      </div>
      <img className="product-ad__illustration hifa" src={HifaAdIllustration} alt="" />
    </article>
  );
};

export const ProductAdsSlider = () => {
  const components = [FeatherInternetAd, HIFAAd, BusinessBankingAd];

  return (
    <SwiperJS>
      {components.map((Component, idx) => (
        <SwiperJS.Slide key={idx}>
          <Component />
        </SwiperJS.Slide>
      ))}
    </SwiperJS>
  );
};
