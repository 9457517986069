import { type SubItems, HeaderNavItems } from "@/constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowLink from "../ArrowLink";
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import "~/fragments/Header.DropDown.scss";

const DropDownLinkItem = (props: SubItems) => {
  const { title, link, icon: Icon, desc } = props;

  return (
    <Link key={title} to={link} className="headerDropDown--card">
      <motion.div
        initial={{ opacity: 0.6 }}
        whileInView={{ opacity: 1 }}
        transition={{ type: "spring", stiffness: 20 }}
        className="icon--wrapper"
      >
        {Icon}
      </motion.div>
      <div className="card--content">
        <h5 className="raven-capitalize">{title}</h5>
        <p>{desc}</p>
      </div>
    </Link>
  );
};

type Props = {
  submenu: Exclude<(typeof HeaderNavItems)[number]["submenu"], undefined>;
  idx: number;
  open: boolean;
};

const HeaderDropDown = ({ submenu, idx, open }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className="headerDropDown">
      <AnimatePresence>
        {open && (
          <motion.div
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // exit={{ opacity: 0 }}
            transition={{ type: "spring", stiffness: 100, damping: 25 }}
            className="headerDropDown--wrapper"
            style={{ overflow: "hidden" }}
          >
            <div className="headerDropDown--container">
              <div className="headerDropDown__row">
                {submenu.subItems.slice(0, 3).map((ele) => (
                  <DropDownLinkItem key={ele.title} {...ele} />
                ))}
              </div>
              <div className="headerDropDown__row">
                {submenu?.subItems.slice(3).map((ele) => (
                  <DropDownLinkItem key={ele.title} {...ele} />
                ))}
              </div>
            </div>
            <div className="headerDropDown--info_container">
              {submenu.additionalContent && (
                <div
                  onClick={() => navigate(submenu.additionalContent?.link ?? pathname)}
                  className={clsx(
                    "headerDropDown--info_container--content raven-block",
                    submenu.additionalContent.link && "raven-cursor-pointer"
                  )}
                >
                  <h5>{submenu.additionalContent.title}</h5>
                  <p>{submenu.additionalContent.desc}</p>
                  {submenu.additionalContent.link && (
                    <ArrowLink
                      to={submenu.additionalContent.link}
                      text={submenu.additionalContent.cta}
                    />
                  )}
                  <motion.img
                    src={submenu.additionalContent.svg}
                    alt={submenu.additionalContent.title}
                    className={clsx(`content--image${idx + 1}`)}
                    initial={{
                      y: idx === 1 ? 10 : 30,
                      opacity: idx === 1 ? 1 : 0.7,
                      rotateY: idx === 1 ? 90 : 0,
                    }}
                    whileInView={{ y: 0, opacity: 1, rotateY: 0 }}
                    transition={{ duration: 0.6 }}
                  />
                </div>
              )}
              {submenu.content && <submenu.content />}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default HeaderDropDown;
